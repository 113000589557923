// 
// charts.scss
//

// Flot chart
.flotTip {
    padding: 8px 12px;
    background-color: $white;
    z-index: 100;
    color: $dark;
    opacity: 1;
    box-shadow: 0 5px 25px 5px rgba($dark, 0.14);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.legend {
    div:first-of-type {
        background-color: transparent !important;
    }

    tr {
        height: 30px;
        font-family: $font-secondary;
    }
}

.legendLabel {
    padding-left: 5px !important;
    line-height: 10px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: $font-weight-semi-bold;
    color: $gray-400;
}

.legendColorBox div div {
    border-radius: 50%;
}

@include media-breakpoint-down(sm) {
    .legend {
        display: none;
    }
}

// Chartist chart
.ct-golden-section:before {
    float: none;
}

.ct-area {
    fill-opacity: .3;
}

.ct-chart {
    max-height: 350px;
    .ct-label {
        fill: $gray-600;
        color: $gray-600;
        font-size: 10px;
        line-height: 1;
    }
}

.ct-grid {
    stroke: rgba($white, 0.1);
}

.ct-chart.simple-pie-chart-chartist {
    .ct-label {
        color: $white;
        fill: $white;
        font-size: 16px;
    }
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
    stroke: $success;
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
    stroke: $info;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
    stroke: $primary;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
    stroke: $danger;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
    stroke: $gray-200;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
    stroke: $pink;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
    stroke: $warning;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
    fill: $success;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
    fill: $info;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
    fill: $primary;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
    fill: $danger;
}

.chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 10px;
    padding: 2px 10px;
    border-radius: 3px;
    background: $gray-100;
    font-weight: $font-weight-medium;
    color: $dark;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    -webkit-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
    opacity: 1;
}

/* Sparkline chart */
.jqstooltip {
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: $white !important;
  box-shadow: $shadow-lg;
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: $white !important;
}

.jqsfield {
  color: $dark !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: $font-family-base !important;
  font-weight: $font-weight-medium !important;
}


// Chartjs
.chartjs-chart {
    margin: auto;
    position: relative;
    width: 100%;
}

.chartjs-chart-example {
    height: 300px;
}