/* =============
  Small Menu
============= */

.side-menu-sm {
    width: $leftbar-width-sm;
    text-align: center;
    #sidebar-menu {
        > ul {
            > li {
                > a {
                    > i {
                        display: block;
                        font-size: 18px;
                        line-height: 24px;
                        width: 100%;
                        margin: 0;
                    }
                }
            }
            ul  {
                a {
                    padding: 10px 20px;
                }
            }
        }
    }
    .menu-arrow,
    .badge {
        display: none !important;
    }
    &+.content-page {
        margin-left: $leftbar-width-sm;
    }
}

.enlarged {
    #wrapper {
        .side-menu-sm {
            text-align: left;

            ul {
                li {
                    a {
                        i {
                            display: inline-block;
                            font-size: 18px;
                            line-height: 17px;
                            margin-left: 3px;
                            margin-right: 15px;
                            vertical-align: middle;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}