// 
// email.scss
//

.inbox-leftbar {
    width: 240px;
    float: left;
    padding: 0 20px 20px 10px;
}

.inbox-rightbar {
    margin-left: 250px;
}

.message-list {
    display: block;
    padding-left: 0;
    li {
        position: relative;
        display: block;
        height: 51px;
        line-height: 50px;
        cursor: default;
        transition-duration: .3s;
        a {
            color: $gray-600;
        }
        &:hover {
            background: rgba($gray-600, 0.15);
            transition-duration: .05s;
        }
        .col-mail {
            float: left;
            position: relative;
        }
        .col-mail-1 {
            width: 320px;
            .star-toggle,
            .checkbox-wrapper-mail,
            .dot {
                display: block;
                float: left;
            }
            .dot {
                border: 4px solid transparent;
                border-radius: 100px;
                margin: 22px 26px 0;
                height: 0;
                width: 0;
                line-height: 0;
                font-size: 0;
            }
            .checkbox-wrapper-mail {
                margin: 15px 10px 0 20px;
            }
            .star-toggle {
                margin-top: 18px;
                font-size: 16px;
                margin-left: 5px;
            }
            .title {
                position: absolute;
                top: 0;
                left: 110px;
                right: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 0;
                line-height: 50px;
            }
        }
        .col-mail-2 {
            position: absolute;
            top: 0;
            left: 320px;
            right: 0;
            bottom: 0;
            .subject,
            .date {
                position: absolute;
                top: 0;
            }
            .subject {
                left: 0;
                right: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .date {
                right: 0;
                width: 170px;
                padding-left: 80px;
            }
        }
    }
    li.active,
    li.mail-selected {
        background: rgba($gray-600, 0.15);
        transition-duration: .05s;
    }
    li.active,
    li.active:hover {
        box-shadow: inset 3px 0 0 $cyan;
    }
    li.unread a {
        font-weight: $font-weight-medium;
        color: $gray-400;
    }
    li.blue-dot .col-mail-1 .dot {
        border-color: $primary;
    }
    li.orange-dot .col-mail-1 .dot {
        border-color: $warning;
    }
    li.green-dot .col-mail-1 .dot {
        border-color: $success;
    }
    .checkbox-wrapper-mail {
        cursor: pointer;
        height: 20px;
        width: 20px;
        position: relative;
        display: inline-block;
        box-shadow: inset 0 0 0 1px $gray-600;
        border-radius: 1px;
        input {
            opacity: 0;
            cursor: pointer;
        }
        input:checked~label {
            opacity: 1;
        }
        label {
            position: absolute;
            top: 3px;
            left: 3px;
            right: 3px;
            bottom: 3px;
            cursor: pointer;
            background: $gray-600;
            opacity: 0;
            margin-bottom: 0 !important;
            transition-duration: .05s;
        }
        label:active {
            background: #87949b;
        }
    }
}

.mail-list {
    a {
        font-family: $font-secondary;
        color: $gray-400;
        padding: 10px 15px;
        display: block;
    }
}

.reply-box {
    border: 2px solid $bg-layout-lighten;
}

@media (max-width: 648px) {
    .inbox-leftbar {
        width: 100%;
    }
    .inbox-rightbar {
        margin-left: 0;
    }
}

@media (max-width: 520px) {
    .message-list li {
        .col-mail-1 {
            width: 150px;
            .title {
                left: 80px;
            }
        }
        .col-mail-2 {
            left: 160px;
            .date {
                text-align: right;
                padding-right: 10px;
                padding-left: 20px;
            }
        }
    }
}