
// 
// topbar.scss
//


#topnav {
  background-color: $bg-topbar;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1001;
}

.navbar-custom {
  border-radius: 0;
  min-height: 70px;
  color: $white;
  z-index: 2;
  padding-right: 15px;

  .nav-link {
    padding: 0;
    line-height: 68px;
    color: rgba($white,0.8);
  }

  .dropdown-toggle {
    &:after {
      content: initial;
    }
  }

  .menu-left {
    overflow: hidden;
  }

  .topbar-right-menu {
    li {
      float: left;
    }
  }

  .logo {
    line-height: 70px;
    width: $leftbar-width;
    display: block;
    text-align: center;
    background: $bg-logo;

    .logo-sm {
      display: none;
    }

    &.logo-light {
      background: $bg-logo-light;
    }
  }

  .dropdown-menu-right {
    transform: none !important;
    top: 100% !important;
    right: 0 !important;
    left: auto !important;
  }

    .navbar-toggle {
      position: relative;

      &:hover,
      &:focus {
          background-color: transparent;
          span {
              background-color: $white;
          }
      }
      .lines {
          width: 25px;
          display: block;
          position: relative;
          padding-top: 27px;
          height: 44px;
          margin: 0 auto;
          transition: all .5s ease;
      }
      span {
          height: 2px;
          width: 100%;
          background-color: rgba($white, 0.5);
          display: block;
          margin-bottom: 5px;
          -webkit-transition: -webkit-transform .5s ease;
          transition: -webkit-transform .5s ease;
          transition: transform .5s ease;
      }
    }

    .navbar-toggle.open {
        span {
            position: absolute;
            &:first-child {
                top: 34px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                visibility: hidden;
            }
            &:last-child {
                width: 100%;
                top: 34px;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }

    .button-menu-mobile {
      display: block;
      height: 70px;
      width: 60px;
      cursor: pointer;
  }
  
  .button-menu-mobile.disable-btn {
      display: none;
  } 
}

/* Search */
.app-search {
  padding-top: 16px;
  padding-right: 20px;

  .form-control,
  .form-control:focus {
    border: none;
    height: 38px;
    color: $white;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba($white,0.1);
    box-shadow: none;
    border-radius: 5px;
    min-width: 180px;
    width: 50%;
  }

  @media (min-width: 630px) and (max-width: 1023px) {
    .form-control,
    .form-control:focus {
      width:35%;
    }
  }
}

/* Notification */
.notification-wrapper {
  max-height: 190px;
}

.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: $white;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 20px;
    padding: 0 15px;
    vertical-align: middle;
  }
  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 8px;
  }
  .notify-item {
    padding: 12px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }
    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $dark;
      font-size: 13px;
      font-weight: $font-weight-medium;
      b {
        font-weight: $font-weight-medium;
      }
      small {
        display: block;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }
    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 20px;
    }
  }
  
  .profile-dropdown {
    .notify-item {
      padding: 7px 20px;
    }
  }
}

.profile-dropdown {
  width: 170px;
  i {
    vertical-align: middle;
    margin-right: 5px;
  }
  span {
    vertical-align: middle;
  }
}

.nav-user {
  padding: 0 12px !important;

  img {
    height:32px;
    width: 32px;
  }
}



// Topbar-light
#topnav.topbar-light {
    background-color: $bg-topbar-light;
    border-bottom: 1px solid $light;

    .navbar-custom {
        .nav-link {
            color: rgba($dark, 0.8);
        }
        .navbar-toggle {
            span {
                background-color: rgba($dark, 0.8);
            }
        }
    }

    /* Search */
    .app-search {
        .form-control,
        .form-control:focus {
            color: $dark;
            background-color: $gray-200;
        }
    }
}

@media (max-width: 1024px) {
  .navbar-custom {
    .button-menu-mobile.disable-btn {
      display: block;
    }  
  }
}

@media (max-width: 768px) {
  .navbar-custom {
    .logo {
      width: 85px;

      .logo-lg {
        display: none;
      }
      .logo-sm {
        display: inline-block;
      }
    }
  }
}

@media (max-width: 640px) {
  .app-search {
    display: none;
  }
  .nav-user {
    span {
      display: none;
    }
  }
  
}