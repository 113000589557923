// 
// pricing.scss
//

.pricing-column {
    position: relative;
    margin-bottom: 40px;
    .inner-box {
        position: relative;
        padding: 0 0 50px;
    }
    .plan-header {
        position: relative;
        padding: 30px 20px 25px;
    }
    .plan-title {
        font-size: 16px;
        margin-bottom: 10px;
        color: $white;
        text-transform: uppercase;
        background-color: $success;
        padding: 15px;
        margin-top: 0;
        margin: 0 -20px;
    }
    .plan-price {
        margin-bottom: 10px;
    }
    .plan-duration {
        font-size: 13px;
    }
    .plan-stats {
        position: relative;
        padding: 30px 20px 15px;
        li {
            margin-bottom: 15px;
            line-height: 24px;
            i {
                font-size: 16px;
                vertical-align: middle;
                margin-right: 5px;
            }
        }
    }
}

.price-ribbon {
    span {
        font-size: 10px !important;
        font-weight: bold;
        letter-spacing: 1px;
    }
}