
// 
// popover-tooltips.scss
//

// Popover
.popover-header {
  margin: 0;
  color: $dark;
}
