
// 
// forms.scss
//

label {
  font-weight: $font-weight-medium;
}

input[type="color"],input[type=range]  {
  min-height: 38px;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  background: lighten($bg-layout-lighten,5%);
  border: none;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $primary;
  margin-top: -4px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: lighten($bg-layout-lighten,10%);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 7px;
  background: lighten($bg-layout-lighten,5%);
  border: none;
  border-radius: 3px;
}
input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $primary;
}
