// 
// form-wizard.scss
//

.wizard>.steps {
    position: relative;
    display: block;
    width: 100%;
}

.wizard.vertical>.steps {
    float: left;
    width: 30%;
}

.wizard>.steps .number {
    font-size: 1.429em;
}

.wizard>.steps>ul>li {
    width: 25%;
}

.wizard>.steps>ul>li,
.wizard>.actions>ul>li {
    float: left;
}

.wizard.vertical>.steps>ul>li {
    float: none;
    width: 100%;
}

.wizard>.steps a,
.wizard>.steps a:hover,
.wizard>.steps a:active {
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em;
    padding: 1em 1em;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard>.steps .disabled a,
.wizard>.steps .disabled a:hover,
.wizard>.steps .disabled a:active {
    background: #eee;
    color: #aaa;
    cursor: default;
}

.wizard>.steps .current a,
.wizard>.steps .current a:hover,
.wizard>.steps .current a:active {
    background: #2184be;
    color: #fff;
    cursor: default;
}

.wizard>.steps .done a,
.wizard>.steps .done a:hover,
.wizard>.steps .done a:active {
    background: #9dc8e2;
    color: #fff;
}

.wizard>.steps .error a,
.wizard>.steps .error a:hover,
.wizard>.steps .error a:active {
    background: #ff3111;
    color: #fff;
}

.wizard>.content {
    background: #eee;
    display: block;
    margin: 0.5em;
    min-height: 35em;
    overflow: hidden;
    position: relative;
    width: auto;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard.vertical>.content {
    float: left;
    margin: 0 2.5% 0.5em 2.5%;
    width: 65%;
}

.wizard>.content>.body {
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%;
}

.wizard>.content>.body ul {
    list-style: disc !important;
}

.wizard>.content>.body ul>li {
    display: list-item;
}

.wizard>.content>.body>iframe {
    border: 0 none;
    width: 100%;
    height: 100%;
}

.wizard>.content>.body input {
    display: block;
    border: 1px solid #ccc;
}

.wizard>.content>.body input[type="checkbox"] {
    display: inline-block;
}

.wizard>.content>.body input.error {
    background: rgb(251, 227, 228);
    border: 1px solid #fbc2c4;
    color: #8a1f11;
}

.wizard>.content>.body label {
    display: inline-block;
    margin-bottom: 0.5em;
}

.wizard>.content>.body label.error {
    color: #8a1f11;
    display: inline-block;
    margin-left: 1.5em;
}

.wizard>.actions {
    position: relative;
    display: block;
    text-align: right;
    width: 100%;
}

.wizard.vertical>.actions {
    float: right;
    margin: 0 2.5%;
    width: 95%;
}

.wizard>.actions>ul {
    display: inline-block;
    text-align: right;
}

.wizard>.actions>ul>li {
    margin: 0 0.5em;
}

.wizard.vertical>.actions>ul>li {
    margin: 0 0 0 1em;
}

.wizard>.actions a,
.wizard>.actions a:hover,
.wizard>.actions a:active {
    background: $primary;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
    background: #eee;
    color: #aaa;
}

.wizard>.content {
    background: transparent;
    min-height: 240px;
    padding: 20px;
    margin-top: 0;
}

.wizard>.content>.body {
    padding: 0;
    position: relative;
    ul>li {
        display: block;
        line-height: 30px;
    }
    label.error {
        color: $danger;
        margin-left: 0;
    }
    label {
        display: inline-block;
        margin-top: 10px;
    }
    input {
        border-color: $input-border-color;

        &:focus {
            border-color: $input-focus-border-color;
        }
    }
}

.wizard>.steps {
    background-color: $bg-layout-lighten;

    .number {
        border-radius: 50%;
        background-color: rgba($white, 0.3);
        display: inline-block;
        line-height: 30px;
        margin-right: 10px;
        width: 30px;
        text-align: center;
    }
    .disabled {
        a,
        a:hover,
        a:active {
            background: transparent;
            color: $gray-500;
            cursor: default;
            border: none;
        }
    }
    .current {
        a {
            background: $cyan;
            &:hover,
            &:active {
                background: $cyan;
                .number {
                    color: $white;
                }
            }
            .number {
                color: $white;
            }
        }
    }
    .done {
        a {
            background: lighten($bg-layout-lighten,3%);
            color: $gray-400;
            &:hover,
            &:active {
                background: lighten($bg-layout-lighten,5%);
                color: $gray-400;
            }
        }
    }
}

.wizard>.steps a,
.wizard>.steps a:hover,
.wizard>.steps a:active {
    border-radius: 2px;
    margin: 0;
}

.wizard>.actions {
    padding: 0 20px;
    margin: 0 !important;
    a,
    a:hover,
    a:active {
        background: $cyan;
        border-radius: 2px;
        color: $white;
    }
    .disabled {
        a,
        a:hover,
        a:active {
            background: lighten($bg-layout-lighten, 5%);
            color: $gray-500;
            cursor: default;
            border: none;
        }
    }
}


/*
    Common 
*/

.wizard,
.tabcontrol {
    display: block;
    width: 100%;
    overflow: hidden;
}

.wizard a,
.tabcontrol a {
    outline: 0;
}

.wizard ul,
.tabcontrol ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
}

.wizard ul>li,
.tabcontrol ul>li {
    display: block;
    padding: 0;
}


/* Accessibility */

.wizard>.steps .current-info,
.tabcontrol>.steps .current-info {
    position: absolute;
    left: -999em;
}

.wizard>.content>.title,
.tabcontrol>.content>.title {
    position: absolute;
    left: -999em;
}

@include media-breakpoint-down(sm) { 
    .wizard > .steps > ul > li,.wizard.vertical > .steps,.wizard.vertical > .content {
        width: 100%;
    }
}