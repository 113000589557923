//
// form-advanced.scss
//

// Select 2

.select2-container {
    width: 100% !important;
    .select2-selection--single {
        border: $input-border-width solid $input-border-color;
        background-color: $input-bg;
        height: 38px;
        .select2-selection__rendered {
            line-height: 36px;
            padding-left: 12px;
            outline: none !important;
            box-shadow: none;
            color: $gray-400;
        }
        .select2-selection__arrow {
            height: 34px;
            width: 34px;
            right: 3px;
            b {
                border-color: darken($light, 15%) transparent transparent transparent;
                border-width: 6px 6px 0 6px;
            }
        }
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent darken($light, 15%) transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 6px 12px;
}

.select2-dropdown {
    color: $dark;
}

.select2-container--default {
    .select2-search--dropdown {
        padding: 10px;
        background-color: lighten($light, 5%);
        .select2-search__field {
            border: 1px solid darken($light, 5%);
            outline: none;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $cyan;
    }
    .select2-results__option[aria-selected=true] {
        background-color: $light;
        color: $dark;
        &:hover {
            background-color: $cyan;
            color: $white;
        }
    }
}

.select2-container .select2-selection--multiple {
    min-height: 38px;
    border: $input-border-width solid $input-border-color !important;
    background-color: $input-bg;
    .select2-selection__rendered {
        padding: 2px 10px;
    }
    .select2-search__field {
        margin-top: 7px;
        color: $input-color;
        border: 0;
    }
    .select2-selection__choice {
        background-color: $cyan;
        border: 1px solid transparent;
        color: $white;
        border-radius: 3px;
        padding: 0 7px;
    }
    .select2-selection__choice__remove {
        color: $white;
        margin-right: 5px;
        &:hover {
            color: $white;
        }
    }
}

/* Bootstrap tagsinput */
.bootstrap-tagsinput {
    box-shadow: none;
    padding: 3px 7px 5px;
    width: 100%;
    line-height: 1;
    border: $input-border-width solid $input-border-color !important;
    background-color: $input-bg;
    .label-info {
        background-color: $primary;
        display: inline-block;
        padding: 4px 8px;
        font-size: 13px;
        margin: 3px 1px;
        border-radius: 3px;
    }
    input {
        color: $input-color;
    }
}


/* Bootstrap select */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100% !important;
}

.bootstrap-select {
    .dropdown-toggle {
        &:focus {
            outline: none !important;
        }
    }
    .dropdown-menu {
        li {
            a {
                outline: none !important;
            }
        }
    }
}


.bootstrap-select .bs-ok-default:after {
    border-color: $primary;
    border-width: 0 .15em .15em 0;
}

.bootstrap-select button {
    overflow: hidden;
    text-overflow: ellipsis;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    margin-top: 0;
}

.bootstrap-select.btn-group .no-results {
    background-color: $white;
    color: $danger;
}


/* Time Picker */
.bootstrap-timepicker-widget table td input {
    height: 32px;
    width: 32px;
    color: $white;
    border: none;
    background-color: $primary;
    border-radius: 50%;
}

.bootstrap-timepicker-widget table td a:hover {
    background-color: transparent;
    border: 1px solid transparent;
    color: $primary;
}

/* Date Range Picker */
.daterangepicker {
    color: $dark;
    border: none;
}
.daterangepicker .ranges li.active,.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $primary;
}


/* Datepicker */

.datepicker-dropdown {
    padding: 10px !important;
}

.datepicker>div {
    display: inherit;
}

.datepicker td,
.datepicker th {
    width: 30px;
    height: 30px;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
    background-color: $primary !important;
    color: $white !important;
    background-image: none !important;
    text-shadow: none !important;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: $primary !important;
    color: $white !important;
    background-image: none !important;
}

.datepicker-inline {
    border: 2px solid $bg-layout-lighten;
    width: 100%;

    table {
        width: 100%;
    }
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover,
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
    background: $gray-200;
}